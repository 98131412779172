<template>
  <div
    class="overflow-y-auto overflow-x-hidden flex flex-column"
  >
    <mobile-group-header
      label="임시 CMS 정보"
      description="빨간 별(*)은 필수 입력 사항입니다. 파란 별(*)모양은 전자계약서 필요 항목입니다."
    />
    <template v-if="!!internalValue">
      <mobile-input
        v-model="internalValue.nickname"
        label="CMS 별칭"
      />
      <mobile-select
        v-model="internalValue.serviceProvider"
        label="CMS 업체"
        :items="selectItems.serviceProvider"
        required
      />
      <mobile-input
        v-model="internalValue.payerName"
        label="납부자 명"
        required
      />
      <mobile-input
        v-model="internalValue.payerPhone"
        label="납부자 연락처"
        required
      />
      <mobile-input
        v-model="internalValue.email"
        label="납부자 이메일"
        required
      />
      <mobile-select
        v-model="internalValue.payerType"
        label="납부자 유형"
        :items="selectItems.payerType"
        required
      />
      <mobile-input
        v-model="internalValue.payerNumber"
        :label="internalValue.payerType === 'I'? '생년월일' :'사업자번호'"
        description="주민번호 앞자리(YYYYMMDD)"
        required
      />
      <mobile-select
        v-model="internalValue.gender"
        label="성별"
        required-blue
        :description="'주민번호 뒷자리 중 맨 처음 숫자'"
        :items="selectItems.gender"
      />
      <mobile-select
        v-model="internalValue.paymentKind"
        required
        :items="selectItems.paymentKind"
        label="결제방법"
      />
      <!-- 결제방법 카드 -->
      <mobile-input
        v-if="internalValue.paymentKind === 'C'"
        v-model="internalValue.cardValidDate"
        required
        label="카드 유효기간"
        description="카드 참고 (MMYY 형식 예시: 0524)"
      />
      <mobile-select
        v-if="!!internalValue.serviceProvider && internalValue.paymentKind === 'B'"
        v-model="internalValue.bankCode"
        :items="bankCodes"
        required
        label="결제은행"
      />
      <mobile-input
        v-model="internalValue.paymentNumber"
        :disabled="!internalValue.paymentKind || internalValue.paymentKind == null"
        :label="internalValue.paymentKind === 'C' ? '카드번호' : '계좌번호'"
        required
      />
      <mobile-input
        v-model="internalValue.reason"
        label="수납목적"
        required-blue
      />
      <mobile-input
        v-model="internalValue.amount"
        label="납부금액"
        type="number"
        unit="원"
        required-blue
      />
      <mobile-select
        v-model="internalValue.billDay"
        :items="itemsOfDay"
        label="납부일"
        required-blue
      />
      <mobile-input
        v-model="internalValue.requester"
        label="신청인"
        required-blue
      />
      <mobile-button
        v-if="!!internalValue.cmsMemberTempId && internalValue.cmsMemberTempId !== 0"
        v-model="internalValue.agreementFile"
        label="CMS 동의서"
        button-type="link"
        @onClick="onClickElectrical"
      />
    </template>
  </div>
</template>

<script>
import {PATH} from "@/constants";

export default {
  name: "CmsTempBase",
  components: {},
  props: {
    accountId: undefined,
    value: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      internalValue: undefined,
      selectItems: {
        serviceProvider: [
          {label: '효성', value: 'F', key: 'provider-type-1'},
          {label: '나이스', value: 'N', key: 'provider-type-2'},
        ],
        payerType: [
          {label: '사업자', value: 'B', key: 'payer-type-1'},
          {label: '개인', value: 'I', key: 'payer-type-2'},
        ],
        paymentKind: [
          {label: '계좌이체', value: 'B', key: 'payment-kind-1'},
          {label: '카드', value: 'C', key: 'payment-kind-2'},
        ],
        gender: [
          {label: '선택안함', value: null, key: 'gender-type-0'},
          {label: '남성 (1)', value: 1, key: 'gender-type-1'},
          {label: '여성 (2)', value: 2, key: 'gender-type-2'},
          {label: '남성 (3)', value: 3, key: 'gender-type-3'},
          {label: '여성 (4)', value: 4, key: 'gender-type-4'},
        ]
      },
      requestData: {
        bankCodeF: [],
        bankCodeN: [],
      },
    }
  },
  computed: {
    itemsOfDay() {
      const result = []
      for (let i = 1; i < 32; i++) {
        result.push({label: `${i}일`, value: i, key: `day-item-${i}`})
      }
      return result
    },

    bankCodes() {
      let result = []
      if (this.internalValue.serviceProvider === 'F') {
        result = this.requestData.bankCodeF.map(e => {
          return {label: e.codeValue, value: e.codeKey, key: `bank-f-item-${e.codeKey}`}
        })
      } else if (this.internalValue.serviceProvider === 'N') {
        result = this.requestData.bankCodeN.map(e => {
          return {label: e.codeValue, value: e.codeKey, key: `bank-n-item-${e.codeKey}`}
        })
      }
      return result
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      }
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.$emit('input', newValue)
      }
    },
    'internalValue.payerType'(newValue, oldValue) {
      if(!oldValue) return
      this.internalValue.payerNumber = undefined
    },
    'internalValue.paymentKind'(newValue, oldValue) {
      if(!oldValue) return
      this.internalValue.paymentNumber = undefined
      this.internalValue.cardValidDate = undefined
    },
  },
  mounted() {
    this.internalValue = this.value
    this.loadMembers()
  },
  methods: {
    async loadMembers() {
      try {
        const requestList = []
        requestList.push(this.loadBankCode('F'))
        requestList.push(this.loadBankCode('N'))
        const result = await Promise.all(requestList)
        this.requestData.bankCodeF = result[0].data
        this.requestData.bankCodeN = result[1].data
      } catch (e) {
        console.error(e)
      }
    },
    async loadBankCode(cmsType) {
      const result = await this.$request(PATH.CMS.GET_BANK_CODE + cmsType).enqueue()
      return result
    },

    async fileDownload(attach) {
      try {
        const url = `${PATH.BASE_URL}${PATH.RESOURCE.FILE}?path=${encodeURI(attach.path)}&filename=${encodeURI(attach.originalFilename)}`;
        window.open(url, "_blank");
      } catch (e) {
        console.error(e);
        this.$alert("파일 경로가 올바르지 않습니다.");
      }
    },

    onClickElectrical() {
      this.$emit('go-electrical', this.internalValue)
    }
  },
}
</script>

<style scoped>
</style>
